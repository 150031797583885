.container {
  max-width: 655px;
  --label-color: black;
}

.footer {
  margin-top: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .footer {
    margin-top: 40px;
    text-align: left;
  }
}