.container {
  display: flex;
  gap: 15px;
  width: 100%;
}

.item {
  flex: 1 1;
}

.label {
  display: block;
  padding-left: 12px;
  font-size: var(--font-size-s);
  line-height: 18px;
  color: var(--label-color);
  text-align: left;
}
