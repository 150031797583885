.container {
  position: relative;
  padding: 2em;
  background: #d0eade;
  border: 1px solid #29b270;
  box-sizing: border-box;
  border-radius: 14px;
  font-size: var(--font-size-xs);
  width: 100%;
  max-width: 35em;
}

.logo {
  width: 4.8em;
  height: 4.8em;
}

.qr {
  display: block;
  box-sizing: border-box;
  width: 7em;
  height: 7em;
}

.photo {
  display: block;
  border: 1px solid #29b270;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  width: 7.3em;
  height: 9em;
  object-fit: cover;
}

.list {
  padding: 0;
  list-style: none;
  line-height: 1.5em;
}

.item {
  display: flex;
  gap: 1em;
  margin-bottom: 4px;
}

.name {
  flex: 0 0 7.5em;
  text-align: right;
}

@media (min-width: 768px) {
  .container {
    font-size: 15px;
  }
}
