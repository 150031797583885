.container {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  z-index: 1001;
  width: 300px;
  padding: 70px 30px;
  background: linear-gradient(
    105.79deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.038) 100.2%
  );
  backdrop-filter: blur(120px);
  border: 1px solid rgba(255, 255, 255, 0.43);
  border-radius: var(--border-radius) 0px 0px var(--border-radius);
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  color: white;
}

.item {
  display: flex;
  gap: 12px;
  margin-bottom: 50px;
  color: white;
}
