.menu {
  margin: 0 16px;
  display: flex;
  gap: 8px;
  padding: 6px;
  background: #e4f2eb;
  border-radius: var(--border-radius);
}

.item {
  display: inline-block;
  padding: 0 4px;
  font-weight: var(--font-weight-semi-bold);
  font-size: 13px;
  line-height: 34px;
  color: rgba(22, 72, 45, 0.7);
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.2s;
  letter-spacing: -0.5px;
}

.item:hover {
  color: rgba(22, 72, 45, 1);
}

.item:global(.active) {
  background-color: #fbfbfb;
  border-radius: 8px;
  color: black;
}

@media (min-width: 768px) {
  .menu {
    position: relative;
    z-index: 1;
    padding: 0;
    gap: 0;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  .item {
    padding: 18px 39px 9px;
    font-weight: var(--font-weight-semi-bold);
    font-size: var(--font-size-xl);
    line-height: 26px;
    color: rgba(0, 0, 0, 0.6);
  }

  .item:global(.active) {
    background-color: #fbfbfb;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }
}
