.container {
  position: relative;
  display: flex;
  gap: 8px;
}

.cell {
  width: 34px;
  height: 42px;
  background: var(--input-background);
  border: 1px solid var(--primary);
  box-sizing: border-box;
  border-radius: var(--border-radius);
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: white;
  outline: none;
}

.cell:focus {
  background: var(--input-background-focus);
}

.cell.invalid {
  background: linear-gradient(rgba(178, 41, 41, 0.2), rgba(141, 26, 26, 0.2));
  border: 1px solid var(--danger);
}

.helper {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  display: block;
  font-size: var(--font-size-m);
  line-height: 26px;
  text-align: center;
  color: white;
}
