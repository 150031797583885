.container {
  margin-top: 28px;
  flex: 1 1;
  background: #fbfbfb;
  box-shadow: 0px -8px 30px rgba(103, 226, 160, 0.3);
  border-radius: var(--border-radius) var(--border-radius) 0px 0px;
  color: black;
}

@media (min-width: 768px) {
  .container {
    margin: 0 16px 50px;
    border-radius: 0 0 12px 12px;
  }
}
