.container {
  background: linear-gradient(
      rgba(32, 31, 29, 0) 0%,
      rgba(126, 103, 59, 0) 200px,
      rgba(32, 31, 29, 0.8) 230px,
      rgba(32, 31, 29, 1) 300px
    ),
    url('../../assets/landscape_mobile_2x.webp') top center no-repeat;
  background-size: 100%, 100% 300px;
}

.back {
  position: absolute;
  top: 40px;
  left: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background: url('../../assets/icons/back.svg') center no-repeat;
  outline: none;
  border: none;
}

.content {
  display: flex;
  flex-flow: column;
  text-align: center;
  min-height: 100vh;
  padding-top: 74px;
  padding-bottom: 40px;
}

.logo {
  margin: 0 auto;
  cursor: pointer;
}

.title {
  margin-top: 24px;
  font-weight: var(--font-semi-bold);
  color: white;
  font-size: var(--font-size-xl);
  line-height: 26px;
}

.subtitle {
  margin-top: 8px;
  padding: 0 20px;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-m);
  line-height: 26px;
  color: white;
}

.footer {
  display: none;
}

@media (min-width: 768px) {
  .container {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    gap: 60px;
    background: linear-gradient(
        rgba(32, 31, 29, 0) 0%,
        rgba(126, 103, 59, 0) calc(100vh - 100px),
        rgba(32, 31, 29, 0.8) calc(100vh - 30px),
        rgba(32, 31, 29, 1) 100vh
      ),
      url('../../assets/landscape_2x.webp') top center no-repeat;
    background-size: 100%, 100% 100vh;
    box-sizing: border-box;
    min-height: 100vh;
    min-width: 100vw;
    padding: 100px 0 0;
  }

  .back {
    display: none;
  }

  .content {
    align-self: center;
    justify-self: center;
    width: 90%;
    max-width: 1232px;
    min-height: 653px;
    padding: 96px 40px 40px;

    background: linear-gradient(
      105.79deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.038) 100.2%
    );
    backdrop-filter: blur(120px);
    border-radius: var(--border-radius);
  }

  .title {
    margin-top: 85px;
    font-size: 30px;
    line-height: 32px;
  }

  .subtitle {
    font-size: var(--font-size-xl);
  }

  .footer {
    display: block;
    width: 100vw;
    height: 40px;
    background: rgba(32, 31, 29, 0.7);
    border-top: 1px solid #7c7c7c;
  }
}
