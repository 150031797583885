@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #28b06f;
  --danger: #b02828;
  --input-background: linear-gradient(rgba(41, 178, 112, 0.2), rgba(26, 141, 86, 0.2));
  --input-background-focus: linear-gradient(rgba(41, 178, 112, 0.4), rgba(26, 141, 86, 0.4));
  --font-size-xs: 10px;
  --font-size-s: 12px;
  --font-size-m: 14px;
  --font-size-l: 16px;
  --font-size-xl: 18px;
  --border-radius: 12px;
  --font-family: Poppins;
  --font-weight-thin: 300;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
