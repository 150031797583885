.actions {
  margin: 120px auto 70px;
  width: 244px;
}

.action {
  margin-bottom: 24px;
  width: 244px;
  padding-left: 0;
  padding-right: 0;
}

.action:last-of-type {
  margin-bottom: 0;
}

.surface {
  min-width: 1232px;
  min-height: 653px;
  background: linear-gradient(
    105.79deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.038) 100.2%
  );
  backdrop-filter: blur(120px);
  border-radius: var(--border-radius);
}

.footer {
  padding: 0 20px;
  text-align: left;
  color: white;
}

.subtitle {
  margin-bottom: 10px;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-bold);
  line-height: 24px;
}

.description {
  margin: 0;
  font-size: var(--font-size-m);
  line-height: 21px;
}

@media (min-width: 768px) {
  .actions {
    margin: 40px auto 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 70px;
    width: auto;
  }

  .action {
    margin-bottom: 0;
  }

  .footer {
    padding: 0;
  }
}
