.table {
  width: 100%;
  font-size: 11px;
  line-height: 26px;
  text-align: left;
  table-layout: fixed;
}

.table + .table {
  margin-top: 14px;
}

.table th {
  font-weight: var(--font-weight-thin);
}

.table td {
  font-weight: var(--font-weight-bold);
}

@media (min-width: 768px) {
  .table + .table {
    margin-top: 24px;
  }

  .table {
    font-size: var(--font-size-m);
  }
}
