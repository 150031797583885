.container {
  flex: 1 1;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  --label-color: white;
}

.submit {
  margin-top: 40px;
  margin-bottom: 16px;
  max-width: 244px;
}

.resend {
  display: block;
  outline: none;
  border: none;
  padding: 0;
  color: #2ada86;
  font-family: var(--font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: 26px;
  text-decoration: underline;
  transition: 0.2s all;
}

.resend:hover {
  opacity: 0.5;
}

@media (min-width: 768px) {
  .container {
    padding: 50px 0 0;
    justify-content: flex-start;
  }

  .email {
    margin: 0 auto;
    max-width: 655px;
  }  

  .submit {
    margin-top: 75px;
    margin-bottom: 8px;
  }
}
