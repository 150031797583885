.container {
  display: flex;
  width: 100%;
  gap: 15px;
}

.prefix {
  flex: 0 0 90px;
}

.number {
  flex: 1 1;
}