.container {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-end;
  gap: 16px;
}

.description {
  font-size: var(--font-size-m);
  line-height: 21px;
  color: var(--label-color);
  text-align: left;
}

.input {
  flex: 0 0 140px;
}

.input {
  display: none;
}

.label {
  flex: 0 0 118px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  box-sizing: border-box;
  height: 118px;
  background: var(--input-background);
  border: 1px solid var(--primary);
  border-radius: 10px;
  outline: none;
  white-space: nowrap;
  user-select: none;
  text-align: center;
  cursor: pointer;
  color: var(--label-color);
}

.preview {
  flex: 0 0 118px;
  width: 118px;
  height: 118px;
  border: 1px solid var(--primary);
  border-radius: 10px;
}

.preview.invalid {
  border-color: var(--danger);
}

.label:hover {
  background: var(--input-background-focus);
}

.label.invalid {
  background: linear-gradient(
    180deg,
    rgba(178, 41, 41, 0.3) 0%,
    rgba(97, 5, 5, 0.3) 100%
  );
  border: 1px solid var(--danger);
}

.actions {
  margin-top: 5px;
  display: flex;
  gap: 20px;
}

.action {
  display: block;
  outline: none;
  border: none;
  padding: 0;
  color: #2ada86;
  font-family: var(--font-family);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: 26px;
  text-decoration: underline;
  transition: 0.2s all;
}

.action:hover {
  opacity: 0.5;
}

.error {
  margin-top: 5px;
  color: var(--danger);
  display: block;
}
