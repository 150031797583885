.container {
  display: flex;
  gap: 10px;
}

.label {
  font-family: var(--font-family);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: var(--font-weight-thin);
  line-height: 26px;
  user-select: none;
  cursor: pointer;
  text-align: left;
}

.input {
  display: none;
}

.input ~ .checkbox {
  margin-top: 4px;
  flex: 0 0 18px;
  display: inline-block;
  height: 18px;
  border: 2px solid #29b270;
  border-radius: 2px;
}

.input:checked ~ .checkbox {
  background-color: #29b270;
  background-image: url('../../assets//icons/tick.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.input.invalid:not(:checked) ~ .checkbox {
  border-color: var(--danger);
}
