.photo {
  display: block;
  border: 1px solid #29b270;
  box-sizing: border-box;
  border-radius: var(--border-radius);
}

.container {
  margin: 24px 0 0;
  display: block;
  width: 100%;
  list-style: none;
  padding: 0;
  --label-color: rgba(0, 0, 0, 1);
}

.field {
  margin-bottom: 8px;
  display: flex;
}

.title {
  flex: 0 0 120px;
  font-weight: var(--font-weight-thin);
  font-size: var(--font-size-m);
  line-height: 26px;
}

.value {
  font-size: var(--font-size-m);
  line-height: 26px;
}
