.container {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.description {
  font-size: var(--font-size-m);
  line-height: 21px;
  color: var(--label-color);
  text-align: left;
}

.input {
  flex: 0 0 140px;
}

.input {
  display: none;
}

.label {
  flex: 0 0 140px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 42px;
  background: var(--input-background);
  border: 1px solid var(--primary);
  border-radius: 10px;
  outline: none;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  color: var(--label-color);
}

.label:hover {
  background: var(--input-background-focus);
}

.label.invalid {
  background: linear-gradient(
    180deg,
    rgba(178, 41, 41, 0.3) 0%,
    rgba(97, 5, 5, 0.3) 100%
  );
  border: 1px solid var(--danger);
}
