.container {
  margin-top: 30px;
  display: block;
  max-width: 655px;
  padding: 0 20px;
  color: white;
  --label-color: white;
}

.field {
  margin-bottom: 17px;
}

.checkbox {
  margin-bottom: 8px;
}

.checkboxes {
  padding-left: 28px;
}

.helper {
  display: block;
  font-weight: var(--font-weight-thin);
  font-size: var(--font-size-s);
  line-height: 26px;
  text-align: left;
}

.submit {
  margin-top: 24px;
}


@media (min-width: 768px) {
  .container {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}
