.title {
  margin: 0 0 24px;
  display: block;
  font-weight: var(--font-weight-thin);
  font-size: var(--font-size-xl);
  line-height: 26px;
}

@media (min-width: 768px) {
  .title {
    font-size: 22px;
  }
}
