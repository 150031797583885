.container {
  position: relative;
  display: inline-block;
}

.container.block {
  display: block;
  width: 100%;
}

.select {
  appearance: none;
  display: block;
  width: 100%;
  padding: 11px 13px 12px;
  background: linear-gradient(rgba(41, 178, 112, 0.2), rgba(26, 141, 86, 0.2)),
    url('../../assets//icons/arrow-down.svg') no-repeat right 9px center;
  border: 1px solid var(--primary);
  box-sizing: border-box;
  border-radius: var(--border-radius);
  font-family: var(--font-family);
  font-size: var(--font-size-m);
  line-height: 21px;
  color: var(--label-color);
  outline: none;
  text-transform: capitalize;
}

.select option {
  color: initial;
}

select:invalid {
  color: gray;
}

.select.invalid {
  background: linear-gradient(
    180deg,
    rgba(178, 41, 41, 0.3) 0%,
    rgba(97, 5, 5, 0.3) 100%
  );
  border: 1px solid var(--danger);
}

.select:focus:not(:invalid) {
  background: linear-gradient(rgba(41, 178, 112, 0.4), rgba(26, 141, 86, 0.4)),
    url('../../assets//icons/arrow-down.svg') no-repeat right 9px center;
}

.label {
  display: block;
  padding-left: 12px;
  font-size: var(--font-size-s);
  line-height: 18px;
  color: var(--label-color);
  text-align: left;
  text-transform: capitalize;
}
