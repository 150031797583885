.container {
  background: linear-gradient(
      rgba(32, 31, 29, 0) 0%,
      rgba(126, 103, 59, 0) 200px,
      rgba(32, 31, 29, 0.8) 230px,
      rgba(32, 31, 29, 1) 300px
    ),
    url('../../assets/landscape_mobile_2x.webp') top center no-repeat;
  background-size: 100%, 100% 300px;
  min-width: 100vw;
}

@media (min-width: 768px) {
  .container {
    background: linear-gradient(
        rgba(32, 31, 29, 0) 0%,
        rgba(126, 103, 59, 0) calc(100vh - 100px),
        rgba(32, 31, 29, 0.8) calc(100vh - 30px),
        rgba(32, 31, 29, 1) 100vh
      ),
      url('../../assets/landscape_2x.webp') top center no-repeat;
    background-size: 100%, 100% 100vh;
  }
}
