.container {
  margin-bottom: 24px;
}

.content {
  margin: 0;
  flex: 1 0 auto;
  padding: 6px 12px;
  background: rgba(189, 189, 189, 0.2);
  border: 1px solid #29b270;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  font-size: var(--font-size-m);
  line-height: 26px;
  letter-spacing: -0.5px;
}

.undo {
  display: none;
}

@media (min-width: 768px) {
  .container {
    display: flex;
    gap: 30px;
  }

  .content {
    padding: 16px 24px;
  }

  .undo {
    padding-left: 26px;
    padding-right: 26px;
    display: flex;
    gap: 12px;
    align-items: center;
    color: #16482d;
  }
}
