.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  box-sizing: border-box;
  overflow: auto;
  padding: 20px 40px;
  background: linear-gradient(
    105.79deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.038) 100.2%
  );
  backdrop-filter: blur(70px);
  border-radius: var(--border-radius);
  border: 1px solid rgba(255, 255, 255, 0.43);
  color: white;
}

.header {
  position: sticky;
  top: 0;
}

button.close {
  position: absolute;
  top: 0;
  right: -30px;
  display: block;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
}

@media (min-width: 768px) {
  .content {
    max-width: 70vw;
    max-height: 70vh;
  }
}
