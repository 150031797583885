.download {
  display: none;
}

.card_wrapper {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .download {
    position: absolute;
    top: 34px;
    right: 40px;
    display: flex;
    align-items: center;
    gap: 16px;
    outline: none;
    background: none;
    border: none;
  }

  .card_wrapper {
    margin-top: 3.5rem;
  }
}
