.container {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  background-color: white;
}

.header {
  padding: 16px;
}

.logo {
  display: block;
  width: 40px;
  height: 40px;
}

.menu {
  display: none;
}

.exit {
  display: none;
}

.footer {
  display: none;
}

@media (min-width: 768px) {
  .header {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    height: 90px;
    padding: 0 43px;
    background: linear-gradient(
      rgba(84, 137, 102, 0),
      rgba(77, 119, 91, 0.057)
    );
    border-bottom: 1px solid #29b270;
  }

  .logo {
    width: 50px;
    height: 50px;
  }

  .menu {
    flex: 1 1;
    margin-left: 70px;
    display: flex;
    align-items: center;
    gap: 60px;
  }

  .item {
    display: flex;
    gap: 17px;
    font-size: var(--font-size-l);
    line-height: 26px;
    color: black;
    text-decoration: none;
    transition: all 0.2s;
  }

  .item:hover {
    color: #29b270;
  }

  .item:global(.active) {
    color: #29b270;
  }

  .exit {
    display: flex;
    gap: 17px;
    font-size: var(--font-size-l);
    line-height: 26px;
    color: black;
    text-decoration: none;
    transition: all 0.2s;
  }

  .exit:hover {
    color: #29b270;
  }

  .burger {
    display: none;
  }

  .footer {
    display: block;
    width: 100vw;
    height: 40px;
    background: rgba(148, 231, 191, 0.14);
    border-top: 1px solid #eaeeec;
  }
}
