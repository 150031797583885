.button {
  border-radius: var(--border-radius);
  color: white;
  display: inline-block;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-bold);
  line-height: 22px;
  padding: 15px 70px;
  border-width: 1px;
  border-style: solid;
  text-transform: uppercase;
}

.button:active {
  border-top-width: 3px;
  padding-bottom: 13px;
}

.button.block {
  display: block;
  width: 100%;
  text-align: center;
  padding: 15px 0;
}

.button.primary {
  background: linear-gradient(rgba(41, 178, 112, 1), rgba(26, 141, 86, 1));
  border-color: #1a7c4d;
}

.button.primary:hover {
  background: linear-gradient(rgba(41, 178, 112, 1), rgba(26, 121, 86, 1));
}

.button.secondary {
  background: linear-gradient(
    rgba(41, 178, 112, 0.3),
    rgba(26, 141, 86, 0.3)
  );
  border-color: var(--primary);
}

.button.secondary:hover {
  background: linear-gradient(
    rgba(41, 178, 112, 0.3),
    rgba(26, 141, 86, 0.6)
  );
}

.button:disabled {
  background: linear-gradient(
    180deg,
    rgba(207, 248, 228, 0.3) 0%,
    rgba(146, 212, 181, 0.3) 100%
  );
  border-color: #9abf99;
  pointer-events: none;
}
