.container {
  position: relative;
  display: inline-block;
}

.container.block {
  display: block;
  width: 100%;
}

.textarea {
  display: block;
  width: 100%;
  padding: 11px 13px 12px;
  background: var(--input-background);
  border: 1px solid var(--primary);
  box-sizing: border-box;
  border-radius: var(--border-radius);
  font-family: var(--font-family);
  font-size: var(--font-size-m);
  line-height: 21px;
  color: var(--label-color);
  outline: none;
}

.textarea:focus {
  background: var(--input-background-focus);
}

.textarea::placeholder {
  text-transform: capitalize;
  color: gray;
}

.textarea.invalid {
  background: linear-gradient(
      180deg,
      rgba(178, 41, 41, 0.3) 0%,
      rgba(97, 5, 5, 0.3) 100%
    ),
    url('../../assets//icons/info.svg') right 8px top 12px no-repeat;
  border: 1px solid var(--danger);
}

.label {
  display: block;
  padding-left: 12px;
  font-size: var(--font-size-s);
  line-height: 18px;
  color: var(--label-color);
  text-align: left;
  text-transform: capitalize;
}

.error {
  display: block;
  position: absolute;
  left: 0;
  bottom: -14px;
  padding-left: 12px;
  font-size: var(--font-size-xs);
  line-height: 12px;
  color: var(--danger);
}