.container {
  position: relative;
  padding: 24px 16px;
}

.title {
  margin-bottom: 10px;
  font-weight: var(--font-weight-thin);
  font-size: 22px;
  line-height: 26px;
}

.subtitle {
  margin-top: 45px;
  margin-bottom: 24px;
  font-weight: var(--font-weight-thin);
  font-size: 22px;
  line-height: 26px;
}

.edit {
  display: none;
}

.remove {
  display: none;
}

@media (min-width: 768px) {
  .container {
    padding: 34px 40px;
  }

  .edit {
    position: absolute;
    top: 34px;
    right: 40px;
    display: flex;
    gap: 12px;
    align-items: center;
    color: #16482d;
  }

  .remove {
    position: absolute;
    top: 24px;
    right: 24px;
    border: none;
    outline: none;
    display: flex;
    gap: 12px;
    align-items: center;
  }
}
