.container {
  padding: 24px 16px;
  border-bottom: 1px solid #7d7c7b;
}

.container:last-child {
  border-bottom: none;
}

@media (min-width: 768px) {
  .container {
    padding: 34px 40px;
  }
}
