.container {
  margin-top: 24px;
  max-width: 655px;
  --label-color: black;
}

.field {
  margin-bottom: 24px;
}

.footer {
  margin-top: 45px;
  text-align: center;
}
